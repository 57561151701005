// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noMatch_wr__3ubvj {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.noMatch_content__1JYP2 {\n  color: #1F1D2B;\n  text-align: center;\n}\n\n.noMatch_text__89fcs {\n  margin-top: 20px;\n  font-size: 16px;\n}\n\n.noMatch_error__PxOUL {\n  font-size: 100px;\n  font-weight: 700;\n}", "",{"version":3,"sources":["webpack://src/pages/NoMatch/noMatch.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".wr {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.content {\n    color: #1F1D2B;\n    text-align: center;\n}\n\n.text {\n    margin-top: 20px;\n    font-size: 16px;\n}\n\n.error {\n    font-size: 100px;\n    font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wr": "noMatch_wr__3ubvj",
	"content": "noMatch_content__1JYP2",
	"text": "noMatch_text__89fcs",
	"error": "noMatch_error__PxOUL"
};
export default ___CSS_LOADER_EXPORT___;
