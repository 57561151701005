import * as React from "react";

// scss
import classes from "./MaintenanceAlert.module.scss";

const MaintenanceAlert = ({ isOpen, message }) => (
    isOpen && (
        <div className={classes.alert}>
            <marquee>{message}</marquee>
        </div>
    )
);

export default MaintenanceAlert;
