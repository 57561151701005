// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Promocodes_head__1QUJB, .Promocodes_spacebetween__1Y_cv {\n  display: flex;\n  flex: 1 1;\n  justify-content: space-between;\n  align-items: center;\n}\n.Promocodes_head__1QUJB .Promocodes_leftmargin__2Yvv7, .Promocodes_spacebetween__1Y_cv .Promocodes_leftmargin__2Yvv7 {\n  margin-left: 10px;\n}\n\n.Promocodes_itemlist__hp5Em {\n  display: flex;\n  margin-top: 10px;\n}", "",{"version":3,"sources":["webpack://src/pages/Settings/Promocodes/Promocodes.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;EACE,aAAA;EACA,gBAAA;AAAF","sourcesContent":[".head, .spacebetween {\n  display: flex;\n  flex: 1;\n  justify-content: space-between;\n  align-items: center;\n\n  & .leftmargin {\n    margin-left: 10px;\n  }\n}\n\n.itemlist {\n  display: flex;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": "Promocodes_head__1QUJB",
	"spacebetween": "Promocodes_spacebetween__1Y_cv",
	"leftmargin": "Promocodes_leftmargin__2Yvv7",
	"itemlist": "Promocodes_itemlist__hp5Em"
};
export default ___CSS_LOADER_EXPORT___;
