// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalKitchenDeliveryTime_fieldset__1dekY {\n  border: 1px solid #DEDEDE;\n  border-radius: 8px;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  grid-gap: 8px;\n  gap: 8px;\n  margin-top: 10px;\n}\n\n.ModalKitchenDeliveryTime_modalBody__2DOa3 {\n  padding: 8px 0 8px 0;\n  grid-gap: 8px;\n  gap: 8px;\n  display: flex;\n  flex-direction: column;\n}\n\n.ModalKitchenDeliveryTime_btnWrapper__2--Fk {\n  display: flex;\n  margin-top: 10px;\n  width: 100%;\n  justify-content: flex-end;\n}\n.ModalKitchenDeliveryTime_btnWrapper__2--Fk button {\n  width: -webkit-min-content;\n  width: min-content;\n}\n\n.ModalKitchenDeliveryTime_goodsReceivementType__1n-ol {\n  display: flex;\n  grid-gap: 8px;\n  gap: 8px;\n}", "",{"version":3,"sources":["webpack://src/components/modals/ModalKitchenDeliveryTime/ModalKitchenDeliveryTime.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,aAAA;EAAA,QAAA;EACA,gBAAA;AACJ;;AAEA;EACI,oBAAA;EACA,aAAA;EAAA,QAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,gBAAA;EACA,WAAA;EACA,yBAAA;AACJ;AACI;EACI,0BAAA;EAAA,kBAAA;AACR;;AASA;EACI,aAAA;EACA,aAAA;EAAA,QAAA;AANJ","sourcesContent":[".fieldset {\n    border: 1px solid #DEDEDE;\n    border-radius: 8px;\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    gap: 8px;\n    margin-top: 10px;\n}\n\n.modalBody {\n    padding: 8px 0 8px 0;\n    gap: 8px;\n    display: flex;\n    flex-direction: column;\n}\n\n.btnWrapper {\n    display: flex;\n    margin-top: 10px;\n    width: 100%;\n    justify-content: flex-end;\n\n    & button {\n        width: min-content;\n    }\n}\n\n// .row {\n//     display: flex;\n//     justify-content: space-between;\n//     align-items: center;\n// }\n\n.goodsReceivementType {\n    display: flex;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": "ModalKitchenDeliveryTime_fieldset__1dekY",
	"modalBody": "ModalKitchenDeliveryTime_modalBody__2DOa3",
	"btnWrapper": "ModalKitchenDeliveryTime_btnWrapper__2--Fk",
	"goodsReceivementType": "ModalKitchenDeliveryTime_goodsReceivementType__1n-ol"
};
export default ___CSS_LOADER_EXPORT___;
