// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Kitchen_kitchenShift__3Ijnq.Kitchen_open__2jolG span {\n  color: green;\n}\n.Kitchen_kitchenShift__3Ijnq:not(.Kitchen_open__2jolG) span {\n  color: red;\n}", "",{"version":3,"sources":["webpack://src/pages/Settings/Kitchens/Kitchen/Kitchen.module.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;AAEI;EACI,UAAA;AAAR","sourcesContent":[".kitchenShift {\n    &.open span {\n        color: green;\n    }\n    &:not(.open) span {\n        color: red;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kitchenShift": "Kitchen_kitchenShift__3Ijnq",
	"open": "Kitchen_open__2jolG"
};
export default ___CSS_LOADER_EXPORT___;
