import styled, { useTheme } from "styled-components";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import InputPhone from "components/ui-kit/Modals/InputPhone";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export const Header = ({
    handleІSybmit = () => { },
    handlerOpenModalAdd = () => { },
    handleShowFilter = () => { },
    updateClientSearchForm,
    searchFormValie,
    isBtnFilter = true,
    isAtiveFilterBtn = false,
}) => {
    const theme = useTheme();

    const onEnter = (e) => {
        if (e.keyCode === 13) {
            handleІSybmit();
        }
    }

    return (
        <Wr theme={theme}>
            <Left>
                <Label>Пошук клієнтів</Label>
            </Left>
            <Right>
                <WrSearch>
                    <TextInput
                        type="text"
                        isTitle={false}
                        isStyle={true}
                        title={"Ім'я"}
                        width="180px"
                        onChange={(e) => updateClientSearchForm("name", e.target.value)}
                        onKeyDown={(e) => onEnter(e)}
                        value={searchFormValie.name}
                        // disabled={!!searchFormValie.address.length}
                    />
                </WrSearch>
                <TextOR>Або</TextOR>
                <WrSearch>
                    <TextInput
                        type="text"
                        isTitle={false}
                        isStyle={true}
                        title={"Адреса"}
                        width="180px"
                        onChange={(e) => updateClientSearchForm("address", e.target.value)}
                        onKeyDown={(e) => onEnter(e)}
                        value={searchFormValie.address}
                        // disabled={!!searchFormValie.name.length}
                    />
                </WrSearch>
                <ButtonIcon
                    onClick={handleІSybmit}
                    title='Пошук'
                    icon={<SearchIcon />}
                    positionIcon="left"
                    disabled={!searchFormValie.address && !searchFormValie.name}
                />
                {
                    isBtnFilter && (
                        <WrBtn>
                            <ButtonIcon
                                onClick={handleShowFilter}
                                title='Фільтр'
                                icon={<FilterAltIcon />}
                                colorBg={isAtiveFilterBtn ? "green" : "#9D9D9D"}
                            />
                        </WrBtn>
                    )
                }
                <WrBtn>
                    <ButtonIcon
                        onClick={handlerOpenModalAdd}
                        icon={<AddIcon />}
                    />
                </WrBtn>
            </Right>
        </Wr>
    )
}

const Wr = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    align-items: center;    
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: ${(p) => p.theme.color25};
`;

const WrSearch = styled.div`
    margin: 0 24px;
`;

const Right = styled.div`
    display: flex;
    align-items: center;
`;

const WrBtn = styled.div`
    margin-left: 24px;
`;

const TextOR = styled.div`
`;