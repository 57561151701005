// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wr__2PXDm {\n  width: 100%;\n  height: 80px;\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  border: 2px dotted #EDA240;\n}\n\n.styles_row__2DPyL {\n  width: 100%;\n  height: 10px;\n  border-radius: 8px;\n  border: 1px solid #eda240;\n  overflow: hidden;\n  position: relative;\n}\n\n.styles_process__23wV- {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  background: #eda240;\n  width: 0;\n}", "",{"version":3,"sources":["webpack://src/components/ProcessDownload/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,0BAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,mBAAA;EACA,QAAA;AACJ","sourcesContent":[".wr {\n    width: 100%;\n    height: 80px;\n    display: flex;\n    align-items: center;\n    padding: 10px;\n    border: 2px dotted #EDA240;\n}\n\n.row {\n    width: 100%;\n    height: 10px;\n    border-radius: 8px;\n    border: 1px solid #eda240;\n    overflow: hidden;\n    position: relative;\n}\n\n.process {\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    background: #eda240;\n    width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wr": "styles_wr__2PXDm",
	"row": "styles_row__2DPyL",
	"process": "styles_process__23wV-"
};
export default ___CSS_LOADER_EXPORT___;
