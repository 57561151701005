// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalBugReport_header__2sxrw {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 5px 10px 0;\n  border-bottom: 1px solid #393c49;\n}\n.ModalBugReport_header__name__1FwLs {\n  font-size: 20px;\n  color: #fff;\n}\n.ModalBugReport_header__close__xP53x {\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/modals/ModalBugReport/ModalBugReport.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,wBAAA;EACA,gCAAA;AACJ;AACI;EACI,eAAA;EACA,WAAA;AACR;AAEI;EACI,eAAA;AAAR","sourcesContent":[".header {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 5px 10px 0;\n    border-bottom: 1px solid #393c49;\n\n    &__name {\n        font-size: 20px;\n        color: #fff;\n    }\n\n    &__close {\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "ModalBugReport_header__2sxrw",
	"header__name": "ModalBugReport_header__name__1FwLs",
	"header__close": "ModalBugReport_header__close__xP53x"
};
export default ___CSS_LOADER_EXPORT___;
