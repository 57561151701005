// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropZone_add_file_zone__3CJJ8 {\n  width: 100%;\n  height: 80px;\n  border-radius: 8px;\n  border: 2px dotted #EDA240;\n  padding: 10px;\n}\n.dropZone_add_file_zone__3CJJ8 .dropZone_presentation__3RCA5 {\n  height: 100%;\n}\n\n.dropZone_bnt_zone__3m3Xc {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.dropZone_bnt_zone__3m3Xc svg {\n  font-size: 34px;\n  color: #EDA240;\n}\n.dropZone_bnt_zone__3m3Xc span {\n  margin-top: 10px;\n}\n\n.dropZone_dragActive__2ykz9 {\n  border-color: green;\n}\n.dropZone_dragActive__2ykz9 svg {\n  color: green;\n}", "",{"version":3,"sources":["webpack://src/components/DropZone/dropZone.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;EACA,aAAA;AACJ;AAAI;EACG,YAAA;AAEP;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAAI;EACI,eAAA;EACA,cAAA;AAER;AAAI;EACI,gBAAA;AAER;;AAEA;EACI,mBAAA;AACJ;AAAI;EACI,YAAA;AAER","sourcesContent":[".add_file_zone {\n    width: 100%;\n    height: 80px;\n    border-radius: 8px;\n    border: 2px dotted #EDA240;\n    padding: 10px;\n    .presentation {\n       height: 100%; \n    }\n}\n\n.bnt_zone {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    svg {\n        font-size: 34px;\n        color: #EDA240;\n    }\n    span {\n        margin-top: 10px;\n    }\n}\n\n.dragActive {\n    border-color: green;\n    svg {\n        color: green;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add_file_zone": "dropZone_add_file_zone__3CJJ8",
	"presentation": "dropZone_presentation__3RCA5",
	"bnt_zone": "dropZone_bnt_zone__3m3Xc",
	"dragActive": "dropZone_dragActive__2ykz9"
};
export default ___CSS_LOADER_EXPORT___;
