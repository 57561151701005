// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Restaurant_cartNotifyImage__f8ssk {\n  width: 145px;\n  height: 145px;\n  border-radius: 10px;\n}", "",{"version":3,"sources":["webpack://src/pages/Settings/Restaurants/Restaurant/Restaurant.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AACJ","sourcesContent":[".cartNotifyImage {\n    width: 145px;\n    height: 145px;\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cartNotifyImage": "Restaurant_cartNotifyImage__f8ssk"
};
export default ___CSS_LOADER_EXPORT___;
