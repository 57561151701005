// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalContainer_modalContainer__q5ZGk {\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  z-index: 20000;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://src/components/modals/ModalContainer/ModalContainer.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,yBAAA;AACJ","sourcesContent":[".modalContainer {\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    z-index: 20000;\n    top: 0;\n    left: 0;\n    display: flex;\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "ModalContainer_modalContainer__q5ZGk"
};
export default ___CSS_LOADER_EXPORT___;
