import api from "../../apiSingleton";

import {
  loadModalClients,
  updadeLoadPageClients,
  closeModalClientsAdd,
  closeModalClients
} from "./view";

import {
  GET_CLIENTS_SUCCESS,
  GET_SOURCES_SUCCESS,
  GET_CLIENTS_EXTENSIVE,
  GET_SOURCE_CLIENT,
  UPDATE_CLIENT_SEARCH_FORM,
  GET_CLIENTS_SEARCH,
  GET_ORDERS_CLIENT,
  UPDATE_FORM_CLIENTS,
  UPDATE_FORM_ADD_CLIENT,
  CLEAR_FORM_ADD_CLIENT,
  UPATE_FORM_CLIENT_BONUS,
  UPATE_FORM_CLIENT_BONUS_CLEAR,
  UPATE_FORM_CLIENT_BONUS_WRITE,
  CALLS_UPDATE_ISLOAD,
  CALLS_UPDATE_FILTER,
  CALLS_GET_LIST_CALLS,
} from "../constants/clients";

import {
  addNotification
} from './notifications';

import { fixedStartDate, getRangeDate } from "utils/helpers/date";

export function updateClientSearchForm(item, data) {
  return {
    type: UPDATE_CLIENT_SEARCH_FORM,
    payload: { item, data },
  };
}

export function getClients(q = "380", signal = undefined) {
  return async (dispatch) => {
    const { data } = await api.clients.getClients({ q }, signal);
    if (signal?.aborted || !data) return;

    await dispatch({
      type: GET_CLIENTS_SUCCESS,
      payload: { data },
    });
  };
}

export function getClientsExtensive(id, isCall = true, getConversations = false) {
  return async (dispatch) => {
    const { data } = await api.clients.getClientsExtensiveInformation(id);

    await dispatch({
      type: GET_CLIENTS_EXTENSIVE,
      payload: data,
    });

    if (isCall) {
      await dispatch({
        type: GET_SOURCE_CLIENT,
        payload: data?.source,
      });
    }

    if (getConversations) {
      await dispatch(getClientCallByPhoneNumber());
    }
  };
}

export function getSources() {
  return async (dispatch) => {
    const { data } = await api.clients.getSources();

    await dispatch({
      type: GET_SOURCES_SUCCESS,
      payload: { data },
    });
  };
}

export function updateClientServer(getClientsS = () => { }) {

  return async (dispatch, state) => {
    const {
      clients: {
        extensive: {
          id,
          sex,
          birthday
        },
        extensive
      }
    } = state();

    let obj = {
      ...extensive,
    };

    sex && (obj = { ...{ sex }, ...obj });
    (birthday != fixedStartDate) && (obj = { ...{ birthday }, ...obj });

    obj = { ...obj, source: extensive.source?.code };

    await dispatch(loadModalClients(true));

    const res = await api.clients.updateClientServer(id, obj);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(closeModalClients());
      await dispatch(addNotification({ war: ['Готово'] }));
      await getClientsS();
    }

    await dispatch(loadModalClients(false));
  };
}

export function clearFormAddClient() {
  return {
    type: CLEAR_FORM_ADD_CLIENT
  }
}

export function addClient(getClientsS = () => { }) {
  return async (dispatch, state) => {
    const {
      clients: {
        addClient: {
          name,
          phone,
          source,
          sex,
          birthday,
          comment
        }
      }
    } = state();

    let obj = {
      name,
      phone,
      source,
      comment
    };

    sex && (obj = { ...{ sex }, ...obj });
    (birthday != fixedStartDate) && (obj = { ...{ birthday }, ...obj });

    await dispatch(loadModalClients(true));
    let res = await api.clients.addClient(obj);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(closeModalClientsAdd());
      await dispatch(clearFormAddClient());
      await dispatch(addNotification({ war: ['Готово'] }));
      await getClientsS();
    }

    await dispatch(loadModalClients(false));
  };
}

export function getClientsSearch(page = 1, params = {}) {
  return async (dispatch, state) => {
    const {
      clients: {
        searchForm: {
          address: clientAddress,
          name
        }
      }
    } = state();

    const q = name || undefined;
    const address = clientAddress || undefined;

    let paramsObj = { q, address, per_page: 100, page };

    if (Object.keys(params).length) {
      paramsObj = { ...paramsObj, ...params };
    }

    dispatch(updadeLoadPageClients(true));

    let res = await api.clients.getClientsPage(paramsObj);

    if (res.status == "Ok") {
      await dispatch({
        type: GET_CLIENTS_SEARCH,
        payload: res,
      });
    }

    dispatch(updadeLoadPageClients(false));
  };
}

export function getOrdersAllClient(id, params = { page: 1, per_page: 10 }) {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const data = await api.clients.getOrdersClient(id, params);

    await dispatch({
      type: GET_ORDERS_CLIENT,
      payload: data,
    });

    await dispatch(updadeLoadPageClients(false));
  };
}

export function getClientExtensiveAndOrder(id, getConversations = false) {
  return async (dispatch, state) => {
    await dispatch(loadModalClients(true));
    await dispatch(getClientsExtensive(id, false, getConversations));
    await dispatch(loadModalClients(false));
  };
}

export function updateClients(item, data) {
  return {
    type: UPDATE_FORM_CLIENTS,
    payload: { item, data }
  }
}

export function updateFormAddClients(item, data) {
  return {
    type: UPDATE_FORM_ADD_CLIENT,
    payload: { item, data }
  }
}

export function updateFormBonusClient(item, data) {
  return {
    type: UPATE_FORM_CLIENT_BONUS,
    payload: { item, data }
  }
}

export function clearFormBonus() {
  return {
    type: UPATE_FORM_CLIENT_BONUS_CLEAR,
  }
}

export function updateBonusWriteClient(value, id, restaurantId) {
  return {
    type: UPATE_FORM_CLIENT_BONUS_WRITE,
    payload: { value, id, restaurantId },
  }
}

export function updateBonusClient() {
  return async (dispatch, state) => {
    const {
      clients: {
        extensive: {
          id
        },
        bonusClient: {
          restaurantId,
          value,
          comment,
          isMinus
        }
      }
    } = state();

    const valueConvert = !isMinus ? +value : +value * -1;
    dispatch(loadModalClients(true));

    const res = await api.clients.updateBonusClient(id, { 
      restaurant_id: restaurantId, 
      value: valueConvert, 
      comment 
    });

    dispatch(loadModalClients(false));

    if (res?.errors) {
      dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getClientExtensiveAndOrder(id));
      dispatch(clearFormBonus());
      dispatch(addNotification({ war: ['Бонуси успішно нараховані'] }, 'success'));
    }
  };
}

export function getOrdersClientOfPhone(phone, state, params = {}) {
  return async () => {
    const fullParams = {
      ...params,
      per_pag: 20,
      sort: "desc",
    }
    await state((prev) => ({ ...prev, isLoad: true }))

    const { data } = await api.clients.getOrdersClientOfPhone(phone, fullParams);

    await state(() => ({ list: data, isLoad: false }))
  };
}

// calls
export function updateLoadCalls(status) {
  return async (dispatch) => {
    await dispatch({
      type: CALLS_UPDATE_ISLOAD,
      payload: status,
    });
  }
}

export function updateFilterCalls(item, data) {
  return async (dispatch) => {
    await dispatch({
      type: CALLS_UPDATE_FILTER,
      payload: { item, data },
    });
  }
}

export function getClientCallByPhoneNumber(externalPhoto = null) {
  return async (dispatch, state) => {
    const {
      clients: {
        extensive: {
          phone
        },
        calls: {
          filter
        }
      }
    } = state();

    let params = {
      number: externalPhoto ? externalPhoto : phone,
      ...getRangeDate(filter.date)
    };

    await dispatch(updateLoadCalls(true));

    try {
      const { data } = await api.clients.getClientCallByPhoneNumber(params);

      await dispatch({
        type: CALLS_GET_LIST_CALLS,
        payload: data,
      });

    } catch (error) {
      console.log("Error get client calls", error);
    }

    await dispatch(updateLoadCalls(false));
  }
}

// end calls







