import Base from './Base.js';

export default class ClientsAPI extends Base {
    getClients(params, signal) {
        return this.apiClient.get('clients/search', params, signal);
    }

    getClientsPage(params) {
        return this.apiClient.get('clients', params);
    }

    addClient(data) {
        return this.apiClient.post('clients', data);
    }

    getClientsExtensiveInformation(id) {
        return this.apiClient.get(`clients/${id}`);
    }

    getSources() {
        return this.apiClient.get('sources');
    }

    updateClientServer(id, data) {
        return this.apiClient.put(`clients/${id}`, data);
    }

    getOrdersClient(id, params) {
        return this.apiClient.get(`clients/${id}/orders`, params);
    }

    updateBonusClient(id, params) {
        return this.apiClient.post(`clients/${id}/wallet/bonuses/add`, params);
    }

    getOrdersClientOfPhone(phone, params) {
        return this.apiClient.get(`clients/${phone}/orders-by-phone`, params);
    }

    getClientCallByPhoneNumber(params) {
        return this.apiClient.get(`clients/calls`, params);
    }
}
