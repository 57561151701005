import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { isArray, debounce } from "lodash";

import { getClients } from "store/actions/clients";
import {
  updateOrderClient,
  updateClient,
  getUpdateOrderClient,
  cleanFormClient,
  createClientOrder,
  clearFormClient
} from "store/actions/order";

import Input from "components/ui-kit/Modals/Input";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import InputPhone from "components/ui-kit/Modals/InputPhone";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import { CalendarDate } from "components/ui-kit/Calendar/Calendar";
import { LoadingModal } from "../../../loadingOrder/loading";

import { sourceMapped } from "utils/mappers/source";
import { reasonMapped, searchReasonSelectByArrayId, reasonInObjOutid } from "utils/mappers/reason";
import { maxDateBirthday, dataFormat, dateNormal } from "utils/helpers/date";
import { currentDate } from 'utils/helpers/date';

import CloseIcon from "@mui/icons-material/Close";
import PhoneInTalkRoundedIcon from "@mui/icons-material/PhoneInTalkRounded";
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";

import { RADIO_FLOOR } from "constants/schemes";

export const ClientSearch = ({
  isOpenOrder,
  openOrderAll,
  status,
  isEdit = true,
}) => {
  const dispatch = useDispatch();
  const debouncedGetClients = useCallback(debounce((...args) => dispatch(getClients(...args)), 250), [dispatch]);

  const {
    clients: {
      list: clients
    },
    order: {
      data: {
        client
      },
      isLoadEditClient,
      isLoadClient,
    },
    settings: {
      resource: sourceList,
      blackList: {
        reasonsList
      }
    }
  } = useSelector(state => state);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [mode, setMode] = useState("search");
  const [isOrdersClients, setIsOrdersClients] = useState(false);

  useEffect(() => {
    if (!!client.id) setMode("preview");
    else if (mode === "preview") setMode("search");
  }, [client.id]);

  useEffect(() => {
    if (phoneNumber.length <= 1) return;

    const controller = new AbortController();
    const signal = controller.signal;

    debouncedGetClients(phoneNumber, signal);
    return () => { controller.abort(); }
    
  }, [debouncedGetClients, phoneNumber]);

  useEffect(() => {
    if (phoneNumber.length >= 1 && clients.length >= 0) {
      setIsOrdersClients(true);
    } else {
      setIsOrdersClients(false);
    }
  }, [phoneNumber, clients]);

  const handleSearch = (number) => {
    let nu = number.replace('+', '');
    // if (nu.length > 1) dispatch(getClients(nu));
    setPhoneNumber(nu);
  };

  const handleClient = (data) => {
    dispatch(getUpdateOrderClient(data.id));
    setMode(false);
    setIsOrdersClients(false);
  };

  const handleNewClient = () => {
    setMode("create");
    dispatch(cleanFormClient());
    dispatch(updateOrderClient("id", null));
    dispatch(updateOrderClient("phone", phoneNumber))
  };

  const clickEdit = () => {
    setMode("edit");
  }

  const handleEdit = () => {
    dispatch(updateClient(setMode));
  }

  const handleCreate = () => {
    dispatch(createClientOrder(setMode));
  }

  const clearFormAddClient = () => {
    setMode("search");
    dispatch(clearFormClient());
  }

  let isBlackList = (isArray(client?.blocking_reasons) && (client?.blocking_reasons.length > 0));

  const renderPreview = () => {
    return (
      <Wrapper mode='fill' isBlackList={isBlackList}>
        <Title mode='fill'>Клієнт</Title>
        {
          isEdit && (
            <ButtonEdit onClick={clickEdit}>
              <SettingsIcon />
            </ButtonEdit>
          )
        }
        <Block>
          <Phone href={`tel:+${client.phone}`}>
            +{client.phone}
            <PhoneInTalkRoundedIcon style={{ marginLeft: 5 }} />
          </Phone>
          <Name>
            {client.name} (ID{client.id})
          </Name>

          {
            isBlackList && (
              <WrBlackList>
                <TitleBlackList>Клієнт в чорному списку:</TitleBlackList>
                <TextBlackList>{client.blocking_reasons_text}</TextBlackList>
              </WrBlackList>
            )
          }

          {
            !!client?.comment && (
              <>
                <br />
                <TitleBlackList>У клієнта є коментар</TitleBlackList>
              </>
            )
          }

          {
            isEdit && (
              <FloatButton mode='fill' onClick={() => clearFormAddClient()}>
                <CloseIcon />
              </FloatButton>
            )
          }
        </Block>
        {
          !isOpenOrder && (
            (status === "draft") && (
              <RowBtns>
                <ButtonIcon
                  onClick={openOrderAll}
                  title="Всі замовлення"
                  colorBg="green"
                />
              </RowBtns>
            )
          )
        }
      </Wrapper >
    );
  };

  const renderCreate = () => {
    return (
      <Wrapper>
        <Title>Новий клієнт</Title>
        <Block>
          <Input
            title="Ім'я"
            type='text'
            value={client.name}
            onChange={(e) =>
              dispatch(updateOrderClient("name", e.target.value))
            }
          />
          <Row>
            <InputPhone
              title='Номер телефону'
              type='text'
              value={client.phone}
              onChange={(value) => dispatch(updateOrderClient("phone", value))}
              width={"230px"}
            />
            <Drob>
              <Dropdown
                title="Джерело"
                type="text"
                width={"auto"}
                onChange={(e) => {
                  dispatch(updateOrderClient("source", e.target.value))
                }}
                list={sourceMapped(sourceList)}
                value={client.source}
              />
            </Drob>
          </Row>
          <WrRange>
            <CalendarDate
              title="Дата народження"
              valueIn={client?.birthday || currentDate()}
              date={dateNormal(client?.birthday || currentDate())}
              maxDate={maxDateBirthday()}
              onChangeCal={(date) => dispatch(updateOrderClient("birthday", dataFormat(date)))}
            />
          </WrRange>
          <Radio
            colors={"#fff"}
            items={RADIO_FLOOR}
            label="Стать"
            valueKey="sex"
            value={client.sex}
            onChange={(e) => dispatch(updateOrderClient("sex", e.value.value))}
          />
          <TextArea
            title="Коментар"
            onChange={(e) => dispatch(updateOrderClient("comment", e.target.value))}
            value={client?.comment || ""}
          />
          <br />
          <FloatButton onClick={() => setMode("search")}>
            <CloseIcon />
          </FloatButton>
          <ButtonIcon
            onClick={handleCreate}
            title="Зберегти"
            colorBg="green"
            icon={<SaveIcon />}
          />
        </Block>

        {
          isLoadEditClient && (
            <LoadingModal />
          )
        }
      </Wrapper>
    );
  };

  const renderEdit = () => {
    return (
      <Wrapper hidden={true}>
        <Title>{client.name}</Title>
        <Block>
          <Input
            title="Ім'я"
            type='text'
            value={client.name}
            onChange={(e) =>
              dispatch(updateOrderClient("name", e.target.value))
            }
          />
          <Row>
            <InputPhone
              title='Номер телефону'
              type='text'
              value={client.phone}
              onChange={(value) => dispatch(updateOrderClient("phone", value))}
              width={"100%"}
            />
          </Row>
          <WrRange>
            <CalendarDate
              title="Дата народження"
              valueIn={client?.birthday}
              date={dateNormal(client?.birthday)}
              maxDate={maxDateBirthday()}
              onChangeCal={(date) => dispatch(updateOrderClient("birthday", dataFormat(date)))}
            />
          </WrRange>
          <Radio
            colors={"#fff"}
            items={RADIO_FLOOR}
            label="Стать"
            valueKey="sex"
            value={client?.sex}
            onChange={(e) => dispatch(updateOrderClient("sex", e.value.value))}
          />
          <TextArea
            title="Коментар"
            onChange={(e) => dispatch(updateOrderClient("comment", e.target.value))}
            value={client?.comment || ""}
          />
          <br />
          <Select
            selectOptions={reasonMapped(reasonsList)}
            selectedOption={searchReasonSelectByArrayId(client.blocking_reasons, reasonsList)}
            label='Причини чорного списку'
            isMulti
            onChange={({ value }) => dispatch(updateOrderClient("blocking_reasons", reasonInObjOutid(value)))}
          />
          <FloatButton onClick={() => setMode("preview")}>
            <CloseIcon />
          </FloatButton>
          <br />
          <ButtonIcon
            onClick={handleEdit}
            title="Зберегти зміни"
            colorBg="green"
            icon={<SaveIcon />}
          />
        </Block>

        {
          isLoadEditClient && (
            <LoadingModal />
          )
        }
      </Wrapper>
    );
  }

  const renderSearch = () => {
    return (
      <Wrapper hidden={!isOrdersClients} style={{ position: "relative" }}>
        <Title>Пошук клієнта <ButtonAddClient onClick={handleNewClient}><AddIcon /></ButtonAddClient></Title>
        <Block>
          <InputPhone
            width='100%'
            type='text'
            value={phoneNumber}
            onChange={handleSearch}
          />
          {isOrdersClients && (
            <>
              <PredictionOpas onClick={() => setIsOrdersClients(false)} />
              <Prediction>
                <PredictionCon >
                  {renderPrediction()}
                  <PredictionItem
                    onClick={handleNewClient}
                    style={{ color: "#EDA240" }}
                  >
                    Новий клієнт +
                  </PredictionItem>
                </PredictionCon>
              </Prediction>
            </>
          )}
        </Block>

        {
          isLoadClient && (
            <LoadingModal text='' />
          )
        }
      </Wrapper>
    );
  };

  const renderPrediction = () => {
    return clients.map((item, index) => {
      return (
        <PredictionItem key={index} onClick={() => handleClient(item)}>
          <Text>+{item.phone}</Text>
          <SubText>{item.name}</SubText>
        </PredictionItem>
      );
    });
  };

  if (mode === "preview") return renderPreview();
  else if (mode === "create") return renderCreate();
  else if (mode === "edit") return renderEdit();
  else return renderSearch();
};

const ButtonAddClient = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EDA240;
  background: transparent;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    color: green;
  }
`;

const TextBlackList = styled.div`
  color: #fff;
  margin-top: 5px;
`;

const TitleBlackList = styled.div`
  color: #fff;
  font-weight: 500;
`;

const WrBlackList = styled.div`
  margin-top: 15px;
`;

const RowBtns = styled.div`
  margin-top: 15px;
`;

const WrRange = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const ButtonEdit = styled.button`
  margin-left: 15px;
  background: transparent;
  width: 24px;
  height: 24px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  right: 15px;
  bottom: 10px;
  &:hover {
    color: green;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  margin: 15px 4px;
  overflow: ${(p) => p.hidden ? 'hidden' : 'inherit'};
  background-color: ${(p) => p.mode === "fill" ? p.isBlackList ? "red" : p.theme.accentColor : p.isBlackList ? "red" : p.theme.background};
  border: 1px solid ${(p) => p.theme.accentColor};
`;

const Title = styled.div`
  font-size: 18px;
  margin: 0px 6px 10px 6px;
  color: ${(p) => p.theme.secondaryColor};
  display: flex;
  align-items: center;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.div`
  margin-left: 6px;
  margin-top: 4px;
  color: ${(p) => p.theme.maskColor};
  font-size: 18px;
`;

const Phone = styled.a`
  display: flex;
  align-items: center;
  width: 190px;
  color: ${(p) => p.theme.secondaryColor};
  margin-left: 3px;
  padding: 3px;
  border-radius: 5px;
  font-size: 20px;
  transition: background-color 200ms;

  &:hover {
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const FloatButton = styled.button`
  position: absolute;
  top: 10px;
  right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  color: ${(p) =>
    p.mode === "fill" ? p.theme.secondaryColor : p.theme.accentColor};
  font-size: 24px;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const Prediction = styled.div`
  position: absolute;
  bottom: 19px;
  left: 13px;
  z-index: 20;
  width: 170px;
  padding: 3px;
  transform-origin: top;
  transform: translateY(100%);
  border: ${(p) => p.theme.inputBorder};
  border-radius: 10px;
  background-color: ${(p) => p.theme.secondaryColor};
`;

const PredictionCon = styled.div`
  position: relative;
  z-index: 10;x
`;

const PredictionOpas = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
`;

const PredictionItem = styled.div`
  padding: 3px;
  margin: 3px;
  border-radius: 5px;
  transition: 200ms background-color;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`;

const Text = styled.div``;

const SubText = styled.div`
  font-size: 14px;
  margin-top: 2px;
`;

const Drob = styled.div`
  width: calc(100% - 240px);
`;

const TitleCalendar = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #fff;
`;